import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatNewLine } from 'bloko/common/trl';

import translation from 'lux/components/translation';

const TrlKeys = {
    editForbidden: 'company.constructor.edit.forbidden',
    pictureFileTooLarge: 'employerconstructor.notify.pictureFileTooLarge',
    unsupportedPictureFileFormat: 'employerconstructor.notify.unsupportedPictureFileFormat',
    invalidPictureSize: 'employerconstructor.notify.invalidPictureSize',
    saveTemplateOk: 'employerconstructor.notify.saveTemplateOk',
    saveTemplateError: 'employerconstructor.notify.saveTemplateError',
    imageResized: 'employerconstructor.notify.imageResized',
    responseTimeOut: 'employerconstructor.notify.responseTimeOut',
    pictureTooLarge: 'employerconstructor.notify.pictureTooLarge',
};

const EditForbiddenError: TranslatedComponent = ({ trls }) => formatNewLine(trls[TrlKeys.editForbidden]);
export const employerConstructorEditForbiddenError = {
    Element: translation(EditForbiddenError),
    kind: 'error',
    autoClose: true,
};

const PictureFileTooLarge: TranslatedComponent = ({ trls }) => formatNewLine(trls[TrlKeys.pictureFileTooLarge]);
export const employerConstructorPictureFileTooLarge = {
    Element: translation(PictureFileTooLarge),
    kind: 'error',
    autoClose: true,
};

const PictureTooLarge: TranslatedComponent<{ maxWidth: number; maxHeight: number }> = ({ trls, maxWidth, maxHeight }) =>
    formatNewLine(
        format(trls[TrlKeys.pictureTooLarge], {
            '{0}': maxWidth,
            '{1}': maxHeight,
        })
    );
export const employerConstructorPictureTooLarge = {
    Element: translation(PictureTooLarge),
    kind: 'error',
    autoClose: true,
};

const UnsupportedPictureFileFormat: TranslatedComponent = ({ trls }) =>
    formatNewLine(trls[TrlKeys.unsupportedPictureFileFormat]);
export const employerConstructorUnsupportedPictureFileFormat = {
    Element: translation(UnsupportedPictureFileFormat),
    kind: 'error',
    autoClose: true,
};

const InvalidPictureSize: TranslatedComponent<{ minimumWidth: number; minimumHeight: number }> = ({
    trls,
    minimumWidth,
    minimumHeight,
}) =>
    formatNewLine(
        format(trls[TrlKeys.invalidPictureSize], {
            '{0}': minimumWidth,
            '{1}': minimumHeight,
        })
    );
export const employerConstructorInvalidPictureSize = {
    Element: translation(InvalidPictureSize),
    kind: 'error',
    autoClose: true,
};

const SaveTemplateOk: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.saveTemplateOk]}</>;
export const employerConstructorSaveTemplateOk = { Element: translation(SaveTemplateOk), kind: 'ok', autoClose: true };

const SaveTemplateError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.saveTemplateError]}</>;
export const employerConstructorSaveTemplateError = {
    Element: translation(SaveTemplateError),
    kind: 'error',
    autoClose: true,
};

const ImageResized: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.imageResized]}</>;
export const employerConstructorImageResized = { Element: translation(ImageResized), kind: 'ok', autoClose: true };

const ResponseTimeOut: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.responseTimeOut]}</>;
export const employerConstructorResponseTimeOut = {
    Element: translation(ResponseTimeOut),
    kind: 'error',
    autoClose: true,
};
