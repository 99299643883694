import { useMemo } from 'react';

import urlParser from 'bloko/common/urlParser';

import { useCdnHost } from 'lux/hooks/useCdnImageHost';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';

const DEFAULT_CROP_WIDTH = 690;
const DEFAULT_CROP_HEIGHT = 397;

const addCropParam = (url, isZp) => {
    const parsedUrl = urlParser(url);
    if (isZp) {
        parsedUrl.params.crop = `${DEFAULT_CROP_WIDTH}x${DEFAULT_CROP_HEIGHT}`;
    }

    return parsedUrl.href;
};

const usePictures = (images) => {
    const cdnHost = useCdnHost();
    const isZp = useIsZarplataPlatform();

    const pictures = useMemo(
        () =>
            images
                .filter(({ path }) => !!path)
                .map(({ path, pictureId, ...restInfo }) => ({
                    ...restInfo,
                    id: pictureId,
                    src: addCropParam(`${cdnHost}${path}`, isZp),
                })),
        [cdnHost, images, isZp]
    );

    return pictures;
};

export default usePictures;
