import getVideoIframeSrc from 'lux/components/EmployerConstructor/widgets/getVideoIframeSrc';

export const addressHasError = (widget, addresses) => {
    if (!widget.addressId) {
        return 'EMPTY';
    }
    return !addresses.some((address) => {
        return address.id === widget.addressId;
    })
        ? 'INVALID'
        : null;
};

export const textHasError = (widget, textMaxLength) => {
    if (!widget.content?.trim().length) {
        return 'EMPTY';
    } else if (widget.content?.length > textMaxLength) {
        return 'INVALID_LENGTH';
    }
    return null;
};

export const galleryHasError = (widget) => {
    if (!widget.items?.length) {
        return 'EMPTY';
    }
    return null;
};

export const pictureHasError = (widget, pictures) => {
    if (!widget.pictureId) {
        return 'EMPTY';
    }
    return !pictures?.some((picture) => {
        return picture.pictureId === widget.pictureId;
    })
        ? 'INVALID'
        : null;
};

export const videoHasError = (widget, videoUrlMaxLength) => {
    if (!widget.url?.trim()) {
        return 'EMPTY';
    }
    if (widget.url?.length > videoUrlMaxLength) {
        return 'INVALID_LENGTH';
    }
    if (!getVideoIframeSrc(widget.url)) {
        return 'INVALID';
    }
    return null;
};

export default ({ widgets, images, settings, addresses }) => {
    const invalidWidgets = widgets
        .map((widget) => {
            let error = null;
            switch (widget.type) {
                case 'GALLERY':
                    error = galleryHasError(widget, images);
                    break;
                case 'PICTURE':
                    error = pictureHasError(widget, images);
                    break;
                case 'ADDRESS':
                    error = addressHasError(widget, addresses);
                    break;
                case 'TEXT':
                    error = textHasError(widget, settings.widgetSettings.textMaxLength);
                    break;
                case 'VIDEO':
                    error = videoHasError(widget, settings.widgetSettings.videoUrlMaxLength);
                    break;
                case 'SEPARATOR':
                    error = null;
                    break;
                default:
                    error = 'UNKNOWN_TYPE';
            }
            return {
                widget,
                error,
            };
        })
        .filter(({ error }) => error);

    return invalidWidgets;
};
