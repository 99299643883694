import { useState } from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Modal, { ModalTitle, ModalHeader, ModalFooter } from 'bloko/blocks/modal';

import useToggleState from 'lux/hooks/useToggleState';

import ImageCrop from 'lux/components/ImageCropPopup/ImageCrop';
import ModalError from 'lux/components/ImageCropPopup/ModalErrorWithHandlingErrors';

const ImageCropPopup = ({
    onClose,
    onDragStop,
    onSave,
    visible,
    resizeInProgress,
    imageCropSettings,
    title,
    description,
    save,
    cancel,
    error,
}) => {
    const [cropImageParams, setCropImageParams] = useState();
    const [dragged, , setDragged] = useToggleState(false);

    /** с таймаутом чтобы в момент, когда зона выделения тянется дальше зоны модалки, попап не закрывался */
    const onDragStopCallback = () => {
        onDragStop?.();
        setTimeout(() => {
            setDragged(false);
        });
    };

    const onDragStart = () => {
        setDragged(true);
    };

    const onSaveWrapper = () => {
        onSave?.(cropImageParams);
    };

    const closePopup = () => {
        if (!dragged) {
            onClose?.(cropImageParams);
        }
    };

    return (
        <Modal visible={visible} onClose={closePopup} className="crop-image-popup">
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <div>{description}</div>
            </ModalHeader>
            <div className="crop-image-popup__content">
                <ImageCrop
                    onResizeCallback={setCropImageParams}
                    onDragStart={onDragStart}
                    onDragStop={onDragStopCallback}
                    containerMaximumHeight={400}
                    {...imageCropSettings}
                />
            </div>
            <div className="crop-image-popup__error">
                <ModalError visible={!!error} errorType={error} />
            </div>
            <ModalFooter>
                <div className="crop-image-popup__footer">
                    <Button onClick={closePopup} data-qa="image-crop-cancel">
                        {cancel}
                    </Button>
                    <Gap left>
                        <Button
                            kind={ButtonKind.Primary}
                            onClick={onSaveWrapper}
                            disabled={resizeInProgress}
                            loading={
                                resizeInProgress && <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />
                            }
                            data-qa="image-crop-save"
                        >
                            {save}
                        </Button>
                    </Gap>
                </div>
            </ModalFooter>
        </Modal>
    );
};

ImageCropPopup.propTypes = {
    onClose: PropTypes.func,
    onDragStop: PropTypes.func,
    onSave: PropTypes.func,
    visible: PropTypes.bool,
    resizeInProgress: PropTypes.bool,
    imageCropSettings: PropTypes.object,
    error: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
};

export default ImageCropPopup;
