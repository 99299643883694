import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ModalError } from 'bloko/blocks/modal';

import translation from 'lux/components/translation';
import {
    EMPLOYER_CONSTRUCTOR_RESPONSE_TIMEOUT,
    INVALID_SELECTION_LOCATION_ERROR,
    INVALID_SELECTION_RATIO_ERROR,
    INVALID_SELECTION_SIZE_ERROR,
} from 'lux/models/employerConstructor/constants';

/**
 *  Усиленный ModalError для вывода ошибки по типу и с переводом
 */
const ModalErrorWithHandleErrors = ({ errorType, visible, trls }) => {
    const error = useMemo(() => {
        if (!visible) {
            return null;
        }

        switch (errorType) {
            case INVALID_SELECTION_LOCATION_ERROR:
            case INVALID_SELECTION_SIZE_ERROR:
            case INVALID_SELECTION_RATIO_ERROR:
                return trls[ModalErrorWithHandleErrors.trls.imageInvalidSelection];
            case EMPLOYER_CONSTRUCTOR_RESPONSE_TIMEOUT:
                return trls[ModalErrorWithHandleErrors.trls.responseTimeOut];
            default:
                return trls[ModalErrorWithHandleErrors.trls.defaultError];
        }
    }, [errorType, trls, visible]);

    return <ModalError visible={visible}>{error}</ModalError>;
};

ModalErrorWithHandleErrors.propTypes = {
    trls: PropTypes.object,
    visible: PropTypes.bool,
    errorType: PropTypes.string,
};

ModalErrorWithHandleErrors.trls = {
    imageInvalidSelection: 'employer.constructor.header.image.invalid.selection',
    responseTimeOut: 'employerconstructor.notify.responseTimeOut',
    defaultError: 'lux.error',
};

export default translation(ModalErrorWithHandleErrors);
