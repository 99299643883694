import { FormEvent, ForwardedRef, forwardRef, useCallback } from 'react';

import { Button, SearchInput, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'lux/components/Form';
import translation from 'lux/components/translation';

import styles from './add-address-modal.less';

const TrlKeys = {
    placeholder: 'employer.address.edit.search.placeholder',
    editSearch: 'employer.address.edit.search',
};

interface AddressSearchFormProps {
    fwdRef?: ForwardedRef<HTMLDivElement>;
    value: string;
    setValue: (searchValue: string) => void;
    onSubmit: (event: FormEvent) => void;
}

const AddressSearchForm: TranslatedComponent<AddressSearchFormProps> = ({
    fwdRef,
    trls,
    value,
    setValue,
    onSubmit,
}) => {
    const { isGtS } = useBreakpoint();
    const searchRef = useCallback((node: HTMLDivElement | null) => isGtS && node?.focus(), [isGtS]);

    return (
        <div ref={fwdRef}>
            <Form className={styles.addressModalSearch} name="add-address-form" onSubmit={onSubmit}>
                <SearchInput
                    clearable
                    ref={searchRef}
                    onChange={setValue}
                    value={value}
                    placeholder={trls[TrlKeys.placeholder]}
                    data-qa="address-edit-search-field"
                />
                <Button data-qa="address-edit-search-submit" type="submit" mode="secondary">
                    {trls[TrlKeys.editSearch]}
                </Button>
            </Form>
            <VSpacing default={24} />
        </div>
    );
};

const Translated = translation(AddressSearchForm);

export default forwardRef<HTMLDivElement, AddressSearchFormProps>((props, ref) => (
    <Translated {...props} fwdRef={ref} />
));
