import { Children, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import RequestAnimation from 'bloko/common/requestAnimation';

import useScroll from 'lux/hooks/useScroll';

import DragElement from 'lux/components/EmployerConstructor/drag/DragElement';
import MovedElementStub from 'lux/components/EmployerConstructor/drag/MovedElementStub';
import { onMove, onDragStart, onDragEnd, getChildrenKeys } from 'lux/components/EmployerConstructor/drag/dragHelpers';

const Container = ({ children, dropZoneClassName, dragElementStubClassName, onDrop, sortKeys, scrollByContainer }) => {
    const onMoveRequestAnimation = useMemo(
        () =>
            RequestAnimation((event, params) => {
                onMove(event, { ...params, scrollByContainer });
            }),
        [scrollByContainer]
    );

    const container = useRef(null);
    const dragElements = useRef([]);
    const lastMouseEvent = useRef({});
    const dragged = useRef(false);
    const [currentDragIndex, setCurrentDragIndex] = useState(null);
    const [dropPositionIndex, setDropPositionIndex] = useState(null);
    const [clickOffset, setClickOffset] = useState({ left: 0, top: 0 });
    const [startPageY, setStartPageY] = useState(0);
    const [movedUp, setMovedUp] = useState();

    useEffect(() => {
        const childrenKeys = getChildrenKeys(children);
        if (childrenKeys.length > sortKeys.length) {
            const newKeys = childrenKeys.filter((key) => !sortKeys.includes(key));
            onDrop([...sortKeys, ...newKeys]);
        } else if (childrenKeys.length < sortKeys.length) {
            const filteredKeys = sortKeys.filter((key) => childrenKeys.includes(key));
            onDrop(filteredKeys);
        }
    }, [children, onDrop, sortKeys]);

    const onDropWrapper = useCallback(
        (from, to) => {
            const newSortKeys = [...sortKeys];
            const elementFrom = newSortKeys.splice(from, 1);
            newSortKeys.splice(to, 0, ...elementFrom);
            onDrop(newSortKeys);
        },
        [onDrop, sortKeys]
    );

    const onMoveWrapper = useCallback(
        (event) => {
            if (dragged.current && event.cancelable) {
                event.preventDefault();
            }
            onMoveRequestAnimation(event, {
                dragged,
                dragElements,
                currentDragIndex,
                lastMouseEvent,
                startPageY,
                container,
                clickOffset,
                setDropPositionIndex,
                setMovedUp,
            });
        },
        [clickOffset, currentDragIndex, onMoveRequestAnimation, startPageY]
    );

    const onScrollMove = useCallback(() => {
        onMoveWrapper(lastMouseEvent.current);
    }, [onMoveWrapper]);

    const onDragStartWrapper = useCallback((event, index) => {
        onDragStart(event, {
            index,
            dragged,
            dragElements,
            container,
            setDropPositionIndex,
            setClickOffset,
            setCurrentDragIndex,
            setStartPageY,
        });
    }, []);

    const onDragEndWrapper = useCallback(() => {
        onDragEnd({
            dragged,
            currentDragIndex,
            dropPositionIndex,
            onDrop: onDropWrapper,
            setDropPositionIndex,
            setCurrentDragIndex,
        });
    }, [currentDragIndex, dropPositionIndex, onDropWrapper]);

    useEffect(() => {
        document.addEventListener('mousemove', onMoveWrapper);
        document.addEventListener('touchmove', onMoveWrapper, { passive: false });
        return () => {
            document.removeEventListener('mousemove', onMoveWrapper);
            document.removeEventListener('touchmove', onMoveWrapper);
        };
    }, [onMoveWrapper]);

    useScroll(onScrollMove);

    useEffect(() => {
        document.addEventListener('mouseup', onDragEndWrapper);
        document.addEventListener('blur', onDragEndWrapper);
        document.addEventListener('touchend', onDragEndWrapper);
        return () => {
            document.removeEventListener('mouseup', onDragEndWrapper);
            document.removeEventListener('blur', onDragEndWrapper);
            document.removeEventListener('touchend', onDragEndWrapper);
        };
    }, [onDragEndWrapper]);

    return (
        <div className="constructor-drag-container" ref={container}>
            {Children.map(children, (child) => {
                const orderIndex = sortKeys.indexOf(Number(child.key));
                const order = orderIndex < 0 ? sortKeys.length : orderIndex;
                const isActive = currentDragIndex === order;
                const elementIsDropPosition = dropPositionIndex === order;
                return (
                    <div key={child.key} style={{ order }}>
                        {isActive && (
                            <MovedElementStub
                                dragElement={dragElements.current[currentDragIndex]}
                                stubClassName={dragElementStubClassName}
                            ></MovedElementStub>
                        )}
                        {movedUp && !isActive && elementIsDropPosition && <div className={dropZoneClassName} />}
                        <DragElement
                            ref={(ref) => {
                                dragElements.current[order] = ref;
                            }}
                            onDragStart={onDragStartWrapper}
                            index={order}
                            active={isActive}
                            hasDraggedElements={currentDragIndex !== null}
                        >
                            {child}
                        </DragElement>
                        {!movedUp && !isActive && elementIsDropPosition && <div className={dropZoneClassName} />}
                    </div>
                );
            })}
        </div>
    );
};

Container.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    dropZoneClassName: PropTypes.string,
    dragElementStubClassName: PropTypes.string,
    scrollByContainer: PropTypes.bool,
    onDrop: PropTypes.func.isRequired,
    sortKeys: PropTypes.arrayOf(PropTypes.number),
};

export default Container;
