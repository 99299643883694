import { useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { employerConstructorUpdateWidgetsOrder, employerConstructorAddWidget } from 'lux/models/employerConstructor';
import Widget from 'lux/models/employerConstructor/widget.types';

import AddWidget from 'lux/components/EmployerConstructor/AddWidget';
import DragContainer from 'lux/components/EmployerConstructor/drag/Container';
import imagePropType from 'lux/components/EmployerConstructor/imagePropType';
import AddressWidget from 'lux/components/EmployerConstructor/widgets/AddressWidget';
import DescriptionWidget from 'lux/components/EmployerConstructor/widgets/DescriptionWidget';
import GalleryWidget from 'lux/components/EmployerConstructor/widgets/GalleryWidget';
import ImageWidget from 'lux/components/EmployerConstructor/widgets/ImageWidget';
import SeparatorWidget from 'lux/components/EmployerConstructor/widgets/SeparatorWidget';
import TextWidget from 'lux/components/EmployerConstructor/widgets/TextWidget';
import VideoWidget from 'lux/components/EmployerConstructor/widgets/VideoWidget';

const getGalleryImages = ({ items = [] }, images) =>
    items.map(({ pictureId }) => images.find(({ pictureId: imagePictureId }) => pictureId === imagePictureId));

const WidgetsList = ({
    widgets,
    widgetsOrder,
    images,
    editMode,
    previewModeWithBranding,
    employerConstructorUpdateWidgetsOrder,
    employerConstructorAddWidget,
}) => {
    const [addressesLoaded, setAddressesLoaded] = useState(false);
    const addressesFetching = useRef(false);

    const widgetsMap = useCallback(
        (sortIds) => {
            const widgetsList = [...widgets];
            if (sortIds) {
                widgetsList.sort((widgetA, widgetB) => {
                    return sortIds.indexOf(widgetA.id) - sortIds.indexOf(widgetB.id);
                });
            }

            return widgetsList.map((widget) => {
                switch (widget.type) {
                    case Widget.Gallery:
                        return (
                            <GalleryWidget
                                key={widget.id}
                                widgetId={widget.id}
                                images={getGalleryImages(widget, images)}
                                editMode={editMode}
                            />
                        );
                    case Widget.Picture:
                        return <ImageWidget key={widget.id} {...widget} images={images} editMode={editMode} />;
                    case Widget.Address:
                        return (
                            <AddressWidget
                                key={widget.id}
                                {...widget}
                                editMode={editMode}
                                addressesLoaded={addressesLoaded}
                                setAddressesLoaded={setAddressesLoaded}
                                addressesFetching={addressesFetching}
                            />
                        );
                    case Widget.Text:
                        return <TextWidget key={widget.id} {...widget} editMode={editMode} />;
                    case Widget.Video:
                        return <VideoWidget key={widget.id} {...widget} editMode={editMode} />;
                    case Widget.Separator:
                        return <SeparatorWidget key={widget.id} {...widget} editMode={editMode} />;
                }
                return null;
            });
        },
        [widgets, images, editMode, addressesLoaded]
    );

    const widgetsRender = () => {
        return editMode ? (
            <DragContainer
                dropZoneClassName="employer-constructor-widgets-drop-line"
                dragElementStubClassName="employer-constructor-widgets-drag-element-stub"
                onDrop={employerConstructorUpdateWidgetsOrder}
                sortKeys={widgetsOrder || widgets.map(({ id }) => id)}
            >
                {widgetsMap()}
            </DragContainer>
        ) : (
            widgetsMap(widgetsOrder)
        );
    };

    return (
        <div className="employer-constructor-widgets-container">
            <DescriptionWidget editMode={editMode} />
            {(editMode || previewModeWithBranding) && !!widgets.length && widgetsRender()}
            {editMode && <AddWidget widgets={widgets} onClick={employerConstructorAddWidget} />}
        </div>
    );
};

WidgetsList.propTypes = {
    widgets: PropTypes.arrayOf(PropTypes.object),
    widgetsOrder: PropTypes.arrayOf(PropTypes.number),
    editMode: PropTypes.bool,
    previewModeWithBranding: PropTypes.bool,
    images: PropTypes.arrayOf(imagePropType),
    employerConstructorUpdateWidgetsOrder: PropTypes.func,
    employerConstructorAddWidget: PropTypes.func,
};

export default connect(
    ({ employerConstructor }) => ({
        widgets: employerConstructor.widgets,
        previewModeWithBranding: employerConstructor.previewModeWithBranding,
        widgetsOrder: employerConstructor.widgetsOrder,
        images: employerConstructor.images,
    }),
    { employerConstructorUpdateWidgetsOrder, employerConstructorAddWidget }
)(WidgetsList);
