import { FC, forwardRef, Ref } from 'react';
import classnames from 'classnames';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import styles from './add-address-modal.less';

interface AddressMapProps {
    fwdRef?: Ref<HTMLDivElement>;
}

const AddressMap: FC<AddressMapProps> = ({ fwdRef }) => {
    const { isMobile } = useBreakpoint();

    return (
        <div
            ref={fwdRef}
            className={classnames(styles.addressModalMapWrapper, {
                [styles.addressModalMapWrapperMobile]: isMobile,
            })}
        />
    );
};

export default forwardRef<HTMLDivElement>((_, ref) => <AddressMap fwdRef={ref} />);
