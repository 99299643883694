import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import WidgetWrapper from 'lux/components/EmployerConstructor/widgets/WidgetWrapper';

import EditModeContainer from 'lux/components/EmployerConstructor/widgets/GalleryWidget/EditModeContainer';
import ViewModeContainer from 'lux/components/EmployerConstructor/widgets/GalleryWidget/ViewModeContainer';
import usePictures from 'lux/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';

const GalleryWidget = ({ images, editMode, dragged, widgetId, getMovedElementProps }) => {
    const pictures = usePictures(images);
    const viewReady = pictures.length > 0;

    const viewRender = useCallback(() => <ViewModeContainer pictures={pictures} />, [pictures]);
    const editRender = useCallback(
        () => <EditModeContainer getMovedElementProps={getMovedElementProps} pictures={pictures} widgetId={widgetId} />,
        [getMovedElementProps, pictures, widgetId]
    );

    return (
        <WidgetWrapper
            dragged={dragged}
            edit={editRender}
            view={viewRender}
            editMode={editMode}
            viewReady={viewReady}
        />
    );
};

GalleryWidget.propTypes = {
    trls: PropTypes.object,
    widgetId: PropTypes.number,
    images: PropTypes.array.isRequired,
    editMode: PropTypes.bool.isRequired,
    dragged: PropTypes.bool,
    getMovedElementProps: PropTypes.func,
};

export default memo(GalleryWidget);
