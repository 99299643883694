import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import Slide from 'bloko/blocks/slide';
import Item from 'bloko/blocks/slide/Item';

import Switcher from 'lux/components/EmployerConstructor/widgets/GalleryWidget/components/Switcher';

const SlidesContainer = ({ slides, activeSlide, setActiveSlide }) => (
    <Fragment>
        <Slide activeItem={activeSlide - 1} onChange={(active) => setActiveSlide(active + 1)}>
            {slides.map(({ id, content: slideContent }) => (
                <Item key={id}>
                    <div className="widget-gallery-slides-item">{slideContent}</div>
                </Item>
            ))}
        </Slide>
        {slides.length > 1 && (
            <div className="widget-gallery-switcher-container">
                <Switcher value={activeSlide} count={slides.length} onChange={setActiveSlide} />
            </div>
        )}
    </Fragment>
);

SlidesContainer.propTypes = {
    slides: PropTypes.array,
    activeSlide: PropTypes.number,
    setActiveSlide: PropTypes.func,
};

export default memo(SlidesContainer);
