import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import resizeImageAction from 'lux/components/EmployerConstructor/resizeImage';
import uploadImageAction from 'lux/components/EmployerConstructor/uploadImage';
import { useNotification } from 'lux/components/Notifications/Provider';
import {
    employerConstructorModifyWidget as modifyWidgetAction,
    setModalError as showErrorAction,
} from 'lux/models/employerConstructor';
import { useSelector } from 'lux/modules/useSelector';

const GALLERY_WIDGET = 'GALLERY_WIDGET';

const addPictureById = (items, pictureId) => [...items, { pictureId }];

const replacePictureById = (items, val1, val2) =>
    items.map((pictureItem) =>
        pictureItem.pictureId !== val1
            ? pictureItem
            : {
                  ...pictureItem,
                  pictureId: val2,
              }
    );

const removePictureById = (items, pictureId) => {
    const cloneItems = [...items];
    const position = cloneItems.map((picture) => picture.pictureId).indexOf(pictureId);

    cloneItems.splice(position, 1);

    return cloneItems;
};

export default ({ widgetId, setCropPictureId }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const widgetData = useSelector(({ employerConstructor }) =>
        employerConstructor.widgets.find(({ id }) => widgetId === id)
    );
    const widgetItems = useMemo(() => widgetData?.items || [], [widgetData]);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const showResizePictureInterface = useCallback(
        (pictureId, typedPictureId) => {
            setCropPictureId(pictureId, typedPictureId);
        },
        [setCropPictureId]
    );

    const resizePictureNoChanges = useCallback(
        (pictureId, typedPictureId) => {
            const items =
                widgetItems.some(({ pictureId: itemPictureId }) => pictureId === itemPictureId) || typedPictureId
                    ? replacePictureById(widgetItems, typedPictureId, pictureId)
                    : addPictureById(widgetItems, pictureId);

            dispatch(
                modifyWidgetAction({
                    id: widgetId,
                    items,
                })
            );
        },
        [dispatch, widgetId, widgetItems]
    );

    const enhancedResizePicture = useCallback(
        (pictureId, typedPictureId, resizeParams) => {
            setIsResizing(true);
            return dispatch(
                resizeImageAction(
                    {
                        resizeParams: {
                            pictureId,
                            ...resizeParams,
                        },
                        widgetId,
                    },
                    {
                        showErrorAction,
                    },
                    addNotification
                )
            )
                .then(({ pictureId: resizedPictureId }) => {
                    const items =
                        widgetItems.some(({ pictureId: itemPictureId }) => pictureId === itemPictureId) ||
                        typedPictureId
                            ? replacePictureById(widgetItems, typedPictureId || pictureId, resizedPictureId)
                            : addPictureById(widgetItems, resizedPictureId);

                    dispatch(
                        modifyWidgetAction({
                            id: widgetId,
                            items,
                        })
                    );
                    setIsResizing(false);
                })
                .catch(() => {
                    setIsResizing(false);
                });
        },
        [addNotification, dispatch, widgetId, widgetItems]
    );

    const enhancedUploadPicture = useCallback(
        (imageFile, typedPictureId) => {
            setIsUploading(true);
            return dispatch(
                uploadImageAction(
                    {
                        file: imageFile,
                        pictureType: GALLERY_WIDGET,
                        widgetId,
                    },
                    addNotification
                )
            )
                .then(({ pictureId }) => {
                    showResizePictureInterface(pictureId, typedPictureId);
                    setIsUploading(false);
                })
                .catch(() => {
                    setIsUploading(false);
                });
        },
        [addNotification, dispatch, showResizePictureInterface, widgetId]
    );

    const removePicture = useCallback(
        (pictureId) => {
            dispatch(
                modifyWidgetAction({
                    id: widgetId,
                    items: removePictureById(widgetItems, pictureId),
                })
            );
        },
        [dispatch, widgetId, widgetItems]
    );

    const setWidgetItemsByIds = useCallback(
        (pictureIds) => {
            dispatch(
                modifyWidgetAction({
                    id: widgetId,
                    items: pictureIds.map((pictureId) => ({ pictureId })),
                })
            );
        },
        [dispatch, widgetId]
    );

    return [
        isUploading,
        isResizing,
        enhancedUploadPicture,
        enhancedResizePicture,
        resizePictureNoChanges,
        removePicture,
        setWidgetItemsByIds,
        showResizePictureInterface,
    ];
};
