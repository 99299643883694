import { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import translation from 'lux/components/translation';

import WidgetControls from 'lux/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'lux/components/EmployerConstructor/widgets/WidgetWrapper';

const SeparatorWidget = ({ trls, editMode, id, getMovedElementProps, dragged }) => {
    const view = useCallback(() => {
        return <hr className="widget-separator" />;
    }, []);

    const edit = useCallback(() => {
        return (
            <Fragment>
                <WidgetControls
                    getMovedElementProps={getMovedElementProps}
                    name={trls[SeparatorWidget.trls.widgetSeparatorName]}
                    upload={false}
                    edit={false}
                    id={id}
                />
                {view()}
            </Fragment>
        );
    }, [id, getMovedElementProps, trls, view]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady />;
};

SeparatorWidget.propTypes = {
    trls: PropTypes.object,
    editMode: PropTypes.bool,
    id: PropTypes.number,
    getMovedElementProps: PropTypes.func,
    dragged: PropTypes.bool,
};

SeparatorWidget.trls = {
    widgetSeparatorName: 'employer.constructor.widget.type.separator',
};

export default translation(SeparatorWidget);
