import urlParser from 'bloko/common/urlParser';

const getUrlInfo = (url: string) => {
    let videoUrl;
    try {
        videoUrl = urlParser(url);
    } catch (error) {
        return null;
    }

    const { hostname, pathname, params } = videoUrl;

    return { hostname, pathname, params };
};

enum HostingType {
    Youtube = 'YOUTUBE',
    Vk = 'VK',
    Vimeo = 'VIMEO',
    Rutube = 'RUTUBE',
}

export const getHosting = (url: string): HostingType | null => {
    const urlInfo = getUrlInfo(url);
    if (urlInfo === null) {
        return null;
    }
    const { hostname } = urlInfo;

    if (/youtu/i.test(hostname)) {
        return HostingType.Youtube;
    }

    if (/vimeo/i.test(hostname)) {
        return HostingType.Vimeo;
    }

    if (/rutube/i.test(hostname)) {
        return HostingType.Rutube;
    }

    if (/vk/i.test(hostname)) {
        return HostingType.Vk;
    }

    return null;
};

const VK_EMBED_HOST = 'https://vk.com/video_ext.php';
const makeVKIFrameHref = (videoInfo: string) => {
    const videoIds = videoInfo.split('video').pop();
    if (!videoIds) {
        return null;
    }
    const [oid, id] = videoIds.split('_');
    const url = urlParser(VK_EMBED_HOST);
    url.params = { oid, id };
    return url.href;
};

const getYoutubeVideoId = (params: Record<string, unknown>, videoPaths: string[]): string | undefined => {
    if (Array.isArray(params.v) && params.v.length > 0) {
        return params.v[0] as string;
    }
    return videoPaths.pop();
};

const getVideoIframeSrc = (url: string): string | null => {
    let iframeUrl: string | null = null;

    const urlInfo = getUrlInfo(url);
    if (urlInfo === null) {
        return null;
    }

    const { hostname, pathname, params } = urlInfo;

    const videoPaths = pathname.replace(/\/$/, '').split('/');
    if (/youtu/i.test(hostname)) {
        const videoId = getYoutubeVideoId(params, videoPaths);
        if (!videoId) {
            return null;
        }
        iframeUrl = `https://youtube.com/embed/${encodeURIComponent(videoId)}`;
    } else if (/vimeo/i.test(hostname)) {
        const videoId = videoPaths.pop();
        if (!videoId) {
            return null;
        }
        iframeUrl = `https://player.vimeo.com/video/${encodeURIComponent(videoId)}`;
    } else if (/rutube/i.test(hostname)) {
        const [, , videoId] = pathname.split('/');
        if (!videoId) {
            return null;
        }
        iframeUrl = `https://rutube.ru/play/embed/${encodeURIComponent(videoId)}`;
    } else if (/vk/i.test(hostname)) {
        const z = params.z;

        if (Array.isArray(z) && z.length > 0) {
            // Для ссылок вида vk.com/user?z=video999999_99999
            const zParam = String(z[0]);
            const [videoInfo] = zParam.split('/');

            if (videoInfo) {
                iframeUrl = makeVKIFrameHref(videoInfo);
            }
        } else {
            // Для ссылок вида vk.com/video999999_99999
            const videoInfo = pathname.split('/').pop();
            if (videoInfo) {
                iframeUrl = makeVKIFrameHref(videoInfo);
            }
        }
    }

    return iframeUrl;
};

export default getVideoIframeSrc;
