import { forwardRef } from 'react';
import classnames from 'classnames';

import ErrorComponent from 'lux/components/EmployerConstructor/ErrorComponent';
import { useSelector } from 'lux/modules/useSelector';

interface WidgetWrapperProps {
    edit: () => void;
    view: () => void;
    editMode: boolean;
    viewReady: boolean;
    dragged: boolean;
    invalid?: string;
}

const WidgetWrapper = forwardRef<HTMLDivElement, WidgetWrapperProps>(
    ({ edit, view, editMode, viewReady, dragged, invalid }, ref) => {
        const webViewMode = useSelector(({ employerConstructorWebViewMode }) => employerConstructorWebViewMode);

        if (!viewReady && !editMode) {
            return null;
        }
        return (
            <>
                <div
                    className={classnames('employer-constructor-widget', {
                        'employer-constructor-widget_view-mode': !editMode,
                        'employer-constructor-widget_webview': webViewMode,
                        'employer-constructor-widget_dragged': dragged,
                    })}
                    ref={ref}
                >
                    <>
                        {editMode && edit()}
                        {viewReady && !editMode && view()}
                    </>
                </div>
                <ErrorComponent show={!!invalid}>{invalid}</ErrorComponent>
            </>
        );
    }
);

export default WidgetWrapper;
