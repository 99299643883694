import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Column from 'bloko/blocks/column';
import Down, { DownPlacement } from 'bloko/blocks/drop/Down';
import NoPaddings from 'bloko/blocks/drop/Down/NoPaddings';
import { PlusScaleSmallEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import scrollToElement from 'Utils/ScrollToElement';
import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import useToggleState from 'lux/hooks/useToggleState';
import Widget, { AddWidgetButtonType } from 'lux/models/employerConstructor/widget.types';
import { useSelector } from 'lux/modules/useSelector';

import AddWidgetButton from 'lux/components/EmployerConstructor/AddWidgetButton';

const WIDGETS_TYPES_DEFAULT = [
    Widget.Text,
    Widget.Picture,
    Widget.Gallery,
    Widget.Video,
    Widget.Address,
    Widget.Separator,
] as const;
const WIDGETS_TYPES_FOR_ZARPLATA = [Widget.Gallery] as const;
const setAutoAddWidget = makeSetStoreField('employerConstructorAutoAddWidget');

interface WidgetParams {
    type: AddWidgetButtonType;
    id: number;
}

interface AddWidgetProps {
    widgets: WidgetParams[];
    onClick: (data: WidgetParams) => void;
    disableToggleOpened?: boolean;
}

const TrlKeys = {
    addWidget: 'employer.constructor.addBlock',
};

const AddWidget: TranslatedComponent<AddWidgetProps> = ({ trls, widgets, onClick, disableToggleOpened }) => {
    const [dropDownHost, setDropDownHost] = useState<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const isZP = useIsZarplataPlatform();
    const widgetTypes = isZP ? WIDGETS_TYPES_FOR_ZARPLATA : WIDGETS_TYPES_DEFAULT;
    useEffect(() => {
        setDropDownHost(wrapperRef.current);
    }, [setDropDownHost]);

    const { countSettings, autoAddWidget } = useSelector(
        ({ employerConstructorSettings, employerConstructorAutoAddWidget }) => ({
            countSettings: employerConstructorSettings?.widgetSettings?.maxCounts,
            autoAddWidget: employerConstructorAutoAddWidget,
        })
    );
    const maxWidgetId = Math.max(...widgets.map(({ id }) => id), 0);
    const [opened, toggleOpened] = useToggleState(true);

    useEffect(() => {
        if (autoAddWidget) {
            dispatch(setAutoAddWidget(null));
            onClick({ type: autoAddWidget, id: maxWidgetId + 1 });
            scrollToElement(wrapperRef.current);
        }
    }, [autoAddWidget, dispatch, maxWidgetId, onClick]);

    const renderBlock = () => (
        <NoPaddings>
            <div className="add-widget-column-wrapper">
                <Column xs="4" s="8" m="9" l="11">
                    <div className="add-widget-content">
                        {widgetTypes.map((type) => {
                            const widgetsCount = widgets.filter((widget) => widget.type === type)?.length;
                            return (
                                <AddWidgetButton
                                    key={type}
                                    limit={countSettings?.[type]}
                                    count={widgetsCount}
                                    type={type}
                                    onClick={() => {
                                        Analytics.sendHHEventButtonClick(`add_widget_${type.toLowerCase()}`);
                                        onClick({ type, id: maxWidgetId + 1 });
                                        !disableToggleOpened && toggleOpened();
                                    }}
                                />
                            );
                        })}
                    </div>
                </Column>
            </div>
        </NoPaddings>
    );

    return (
        <div ref={wrapperRef} className="add-widget-wrapper">
            <div className="employer-constructor-widget">
                <Down
                    show={opened}
                    placement={DownPlacement.BottomStart}
                    onlySetPlacement={true}
                    render={renderBlock}
                    flexible={true}
                    showCloseButton={false}
                    host={dropDownHost}
                >
                    <div className="add-widget" onClick={toggleOpened}>
                        <span className="add-widget__plus">
                            <PlusScaleSmallEnclosedFalse initial={IconColor.Blue60} />
                        </span>{' '}
                        {trls[TrlKeys.addWidget]}
                    </div>
                </Down>
            </div>
        </div>
    );
};

export default translation(AddWidget);
