import { useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Text, { TextSize } from 'bloko/blocks/text';

import scrollToElement from 'Utils/ScrollToElement';
import TextContentEditor from 'lux/components/Employer/TextContentEditor';
import WidgetTip from 'lux/components/EmployerConstructor/WidgetTip';
import RawUserContent from 'lux/components/RawUserContent';
import translation from 'lux/components/translation';
import { setDescription } from 'lux/models/employer/employerInfo';
import { resetSaveDescriptionErrorType } from 'lux/models/employerConstructor';
import Widget from 'lux/models/employerConstructor/widget.types';

import WidgetControls from 'lux/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'lux/components/EmployerConstructor/widgets/WidgetWrapper';

const DescriptionWidget = ({
    trls,
    editMode,
    description,
    setDescription,
    saveDescriptionErrorType,
    resetSaveDescriptionErrorType,
}) => {
    const viewReady = !!description;
    const widgetWrapperRef = useRef(null);

    useEffect(() => {
        if (saveDescriptionErrorType) {
            scrollToElement(widgetWrapperRef.current, { centered: false, topOffset: 0 });
        }
    }, [saveDescriptionErrorType]);

    const view = useCallback(() => {
        return (
            <Text size={TextSize.Large}>
                <RawUserContent content={description} />
            </Text>
        );
    }, [description]);

    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Description}>
                <WidgetControls
                    edit={false}
                    upload={false}
                    drag={false}
                    remove={false}
                    name={trls[DescriptionWidget.trls.widgetCompanyDescriptionName]}
                />
                <TextContentEditor
                    value={description}
                    onChange={setDescription}
                    onFocus={resetSaveDescriptionErrorType}
                    invalid={!!saveDescriptionErrorType}
                />
            </WidgetTip>
        );
    }, [description, resetSaveDescriptionErrorType, saveDescriptionErrorType, setDescription, trls]);

    const errorTrl = saveDescriptionErrorType ? trls[DescriptionWidget.trls[saveDescriptionErrorType]] : null;

    return (
        <WidgetWrapper
            edit={edit}
            view={view}
            editMode={editMode}
            viewReady={viewReady}
            ref={widgetWrapperRef}
            invalid={errorTrl}
        />
    );
};

DescriptionWidget.propTypes = {
    trls: PropTypes.object,
    editMode: PropTypes.bool,
    description: PropTypes.string,
    setDescription: PropTypes.func,
    saveDescriptionErrorType: PropTypes.string,
    resetSaveDescriptionErrorType: PropTypes.func,
};

DescriptionWidget.trls = {
    widgetCompanyDescriptionName: 'employer.constructor.widgetname.companyDescription',
    TEXT_TOO_LONG: 'employer.edit.description.error.text_too_long',
    TEXT_TOO_SHORT: 'employer.edit.description.error.text_too_short',
    TOO_MANY_CAPS: 'employer.edit.description.error.too_many_caps',
    UNKNOWN: 'employer.edit.description.error.unknown',
};

export default connect(
    ({ employerInfo, employerConstructor }) => ({
        description: employerInfo.description,
        saveDescriptionErrorType: employerConstructor.saveDescriptionErrorType,
    }),
    { setDescription, resetSaveDescriptionErrorType }
)(translation(DescriptionWidget));
