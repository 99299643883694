import { Input } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

interface AddressDescriptionProps {
    value: string;
    placeholder: string;
    'data-qa': string;
    onChange: (description: string) => void;
}

const AddressDescription: TranslatedComponent<AddressDescriptionProps> = ({
    trls,
    placeholder,
    onChange,
    ...props
}) => {
    return <Input {...props} onChange={onChange} placeholder={placeholder} />;
};

export default translation(AddressDescription);
