import { FC, memo, MutableRefObject } from 'react';

import { Card, Cell, CellText, VSpacingContainer, Loader } from '@hh.ru/magritte-ui';

import { GeoObjectsContext } from 'Modules/Maps/maps.types';

import { AddressState, YandexCity } from 'lux/components/AddressSuggest/types';

import styles from './add-address-modal.less';

interface AddressSearchResultsProps {
    isLoading: boolean;
    addressState: AddressState;
    cities: YandexCity[];
    setAddressState: (addressState: AddressState) => void;
    setFullAddress: (fullAddress: string) => void;
    setCoordinates: (coordinates: number[]) => void;
    setCity: (city: string) => void;
    updateMap: (geoObjects: GeoObjectsContext, draggable: boolean) => void;
    currentGeoObjectsContextRef: MutableRefObject<GeoObjectsContext | undefined>;
}

const AddressSearchResults: FC<AddressSearchResultsProps> = ({
    isLoading,
    addressState,
    cities,
    currentGeoObjectsContextRef,
    setAddressState,
    setFullAddress,
    setCoordinates,
    setCity,
    updateMap,
}) => {
    if (addressState !== AddressState.Search) {
        return null;
    }

    if (isLoading) {
        return (
            <div className={styles.loadingWrapper}>
                <Loader initial="accent" size={24} />
            </div>
        );
    }

    const handleClick = (city: YandexCity) => {
        setAddressState(AddressState.Address);
        setFullAddress(city.fullAddress);
        setCoordinates(city.coordinates);
        setCity(city.localityName);
        updateMap(city.context, true);
        currentGeoObjectsContextRef.current = city.context;
    };

    return (
        <VSpacingContainer default={12}>
            {cities.map((city, index) => (
                <Card
                    key={`city${index}`}
                    stretched
                    showBorder
                    padding={12}
                    borderRadius={12}
                    data-qa="address-edit-search-result"
                >
                    <Cell onClick={() => handleClick(city)}>
                        <CellText type="title">{city.address}</CellText>
                        <CellText type="subtitle">{city.city}</CellText>
                    </Cell>
                </Card>
            ))}
        </VSpacingContainer>
    );
};

export default memo(AddressSearchResults);
