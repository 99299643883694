import { useCallback, useEffect, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { PenSquareScaleSmall, CameraScaleSmall } from 'bloko/blocks/icon';
import { format, formatNewLine } from 'bloko/common/trl';

import CdnImg from 'lux/components/CdnImg';
import WidgetTip from 'lux/components/EmployerConstructor/WidgetTip';
import imageCropSettingsPropType from 'lux/components/EmployerConstructor/imageCropSettingsPropType';
import resizeImage from 'lux/components/EmployerConstructor/resizeImage';
import uploadImage from 'lux/components/EmployerConstructor/uploadImage';
import ImageCropPopup from 'lux/components/ImageCropPopup';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import {
    Status,
    employerConstructorModifyWidget,
    setModalError as setModalErrorAction,
} from 'lux/models/employerConstructor';
import Widget from 'lux/models/employerConstructor/widget.types';

import WidgetControls from 'lux/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'lux/components/EmployerConstructor/widgets/WidgetWrapper';
import FileLoadButton from 'lux/components/EmployerConstructor/widgets/components/FileLoadButton';
import LoadArea from 'lux/components/EmployerConstructor/widgets/components/LoadArea';

const ImageWidget = ({
    trls,
    id,
    resizeStatus,
    editMode,
    pictureId,
    pictureType,
    uploadImage,
    resizeImage,
    imageCropSettings,
    getMovedElementProps,
    dragged,
    employerConstructorModifyWidget,
    modalError,
    setModalError,
    images,
}) => {
    const [cropPictureId, setCropPictureId] = useState(null);
    const cropPicture = useMemo(
        () => images.find((image) => image.pictureId === cropPictureId),
        [cropPictureId, images]
    );
    const imageParams = useMemo(() => images.find((image) => image.pictureId === pictureId), [images, pictureId]);
    const { addNotification } = useNotification();

    const viewReady = !!imageParams?.path;

    const resetModalError = useCallback(() => setModalError(null), [setModalError]);

    /**
     * Чистим ошибка crop модалки при закрытии окна
     */
    useEffect(() => {
        !cropPictureId && resetModalError();
    }, [cropPictureId, resetModalError]);

    const onUpload = useCallback(
        (event) => {
            const imageFile = event.target ? event.target.files[0] : event[0];
            uploadImage({ file: imageFile, pictureType, widgetId: id }, addNotification)
                .then((data) => {
                    setCropPictureId(data.pictureId);
                    employerConstructorModifyWidget({ resizeStatus: Status.Dirty, id });
                    return null;
                })
                .catch(console.error);
        },
        [addNotification, employerConstructorModifyWidget, id, pictureType, uploadImage]
    );

    const onSavePicture = useCallback(
        (cropParams) => {
            if (cropParams.noChanges) {
                pictureId !== cropPictureId &&
                    employerConstructorModifyWidget({
                        id,
                        pictureId: cropPictureId,
                    });
                setCropPictureId(null);
                return;
            }

            resetModalError();
            resizeImage(
                {
                    resizeParams: {
                        pictureId: cropPictureId,
                        originalPath: cropPicture ? cropPicture.originalPath : null,
                        ...cropParams.absoluteSizes,
                    },
                    widgetId: id,
                },
                {
                    showErrorAction: setModalErrorAction,
                },
                addNotification
            )
                .then(() => setCropPictureId(null))
                .catch(console.error);
        },
        [
            resetModalError,
            resizeImage,
            cropPictureId,
            cropPicture,
            id,
            addNotification,
            pictureId,
            employerConstructorModifyWidget,
        ]
    );

    const uploadImageButton = useCallback(
        (getInputProps) => (
            <FileLoadButton
                iconPosition="left"
                isLoading={resizeStatus === Status.Fetching}
                icon={<CameraScaleSmall />}
                inputProps={getInputProps()}
            >
                {trls[ImageWidget.trls.widgetImageUploadText]}
            </FileLoadButton>
        ),
        [resizeStatus, trls]
    );

    const disabledResizeButton = useCallback(() => {
        const disabled =
            cropPicture?.originalWidth < imageCropSettings.minWidth ||
            cropPicture?.originalHeight < imageCropSettings.minHeight;
        return (
            disabled && (
                <HoverTip
                    host={!process.env.LUX_SERVER ? document.body : null}
                    render={() => trls[ImageWidget.trls.invalidImage]}
                >
                    <div className="widget-controls__button-wrapper">
                        <Button disabled icon={<PenSquareScaleSmall />} />
                    </div>
                </HoverTip>
            )
        );
    }, [cropPicture, imageCropSettings.minHeight, imageCropSettings.minWidth, trls]);

    const view = useCallback(
        () => (
            <div className="widget-image">
                <CdnImg
                    loading="lazy"
                    path={imageParams.path}
                    className="widget-image__image"
                    alt={trls[ImageWidget.trls.widgetImageName]}
                />
            </div>
        ),
        [imageParams, trls]
    );

    const loadArea = useCallback(
        (getInputProps) => (
            <LoadArea
                hintText={formatNewLine(
                    format(trls[ImageWidget.trls.loadAreaDescription], {
                        '{0}': imageCropSettings.minWidth,
                        '{1}': imageCropSettings.minHeight,
                        '{2}': Math.floor(imageCropSettings.maxSizeBytes / 1024 / 1024),
                    })
                )}
                buttonText={trls[ImageWidget.trls.uploadButton]}
                isLoading={resizeStatus === Status.Fetching}
                inputProps={getInputProps()}
            />
        ),
        [trls, imageCropSettings, resizeStatus]
    );

    const renderEditContent = useCallback(
        ({ getRootProps, getInputProps, isDragActive }) => {
            return (
                <div
                    className={classnames('widget-image-drop-container', {
                        'widget-image-drop-container_drop': isDragActive,
                    })}
                    {...getRootProps()}
                >
                    <WidgetControls
                        upload={viewReady}
                        getMovedElementProps={getMovedElementProps}
                        edit={viewReady}
                        onEdit={() => setCropPictureId(pictureId)}
                        editOverride={disabledResizeButton()}
                        uploadOverride={uploadImageButton(getInputProps)}
                        name={trls[ImageWidget.trls.widgetImageName]}
                        id={id}
                    />
                    {viewReady && view()}
                    {!viewReady && loadArea(getInputProps)}
                </div>
            );
        },
        [viewReady, getMovedElementProps, disabledResizeButton, uploadImageButton, trls, id, view, loadArea, pictureId]
    );

    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Picture}>
                <Dropzone
                    multiple={false}
                    disableClick={true}
                    accept={imageCropSettings.allowedMimeTypes}
                    onDrop={onUpload}
                >
                    {renderEditContent}
                </Dropzone>
                <ImageCropPopup
                    error={modalError}
                    onClose={() => setCropPictureId(null)}
                    onDragStop={resetModalError}
                    onSave={onSavePicture}
                    visible={!!cropPicture}
                    resizeInProgress={resizeStatus === Status.Fetching}
                    imageCropSettings={{
                        src: cropPicture?.originalPath,
                        stateX: cropPicture?.selectionLeft,
                        stateY: cropPicture?.selectionTop,
                        stateWidth: cropPicture?.selectionWidth,
                        stateHeight: cropPicture?.selectionHeight,
                        originalWidth: cropPicture?.originalWidth,
                        originalHeight: cropPicture?.originalHeight,
                        ratio: imageCropSettings.widthHeightRatio,
                        minimumWidth: imageCropSettings.minWidth,
                        minimumHeight: imageCropSettings.minHeight,
                    }}
                    title={trls[ImageWidget.trls.cropPopupTitle]}
                    description={trls[ImageWidget.trls.cropPopupDescription]}
                    save={trls[ImageWidget.trls.cropPopupSave]}
                    cancel={trls[ImageWidget.trls.cropPopupCancel]}
                />
            </WidgetTip>
        );
    }, [
        onUpload,
        renderEditContent,
        modalError,
        resetModalError,
        onSavePicture,
        cropPicture,
        resizeStatus,
        imageCropSettings,
        trls,
    ]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady={viewReady} />;
};

ImageWidget.propTypes = {
    trls: PropTypes.object,
    editMode: PropTypes.bool,
    id: PropTypes.number,
    pictureId: PropTypes.number,
    pictureType: PropTypes.string,
    resizeStatus: PropTypes.string,
    images: PropTypes.array,
    uploadImage: PropTypes.func,
    resizeImage: PropTypes.func,
    modalError: PropTypes.string,
    setModalError: PropTypes.func,
    imageCropSettings: imageCropSettingsPropType,
    getMovedElementProps: PropTypes.func,
    dragged: PropTypes.bool,
    employerConstructorModifyWidget: PropTypes.func,
};

ImageWidget.defaultProps = {
    images: [],
    imageCropSettings: {},
};

ImageWidget.trls = {
    loadAreaDescription: 'employer.constructor.widget.image.loadareadescription',
    uploadButton: 'employer.constructor.widget.image.uploadButton',

    widgetImageName: 'employer.constructor.widgetname.image',
    widgetImageUploadText: 'employer.constructor.widgetupload.text',

    cropPopupTitle: 'employer.constructor.widget.image.cropimage.title',
    cropPopupDescription: 'employer.constructor.widget.image.cropimage.description',
    cropPopupSave: 'employer.constructor.cropimage.button.save',
    cropPopupCancel: 'employer.constructor.cropimage.button.cancel',

    invalidImage: 'employer.constructor.widget.image.invalid',
};

export default connect(
    ({ employerConstructorSettings, employerConstructor }) => {
        const pictureType = employerConstructorSettings?.widgetSettings?.pictureTypeByWidgetType?.PICTURE;
        return {
            pictureType,
            imageCropSettings: employerConstructorSettings?.pictureSettings[pictureType],
            modalError: employerConstructor.modalError,
        };
    },
    { uploadImage, resizeImage, setModalError: setModalErrorAction, employerConstructorModifyWidget }
)(translation(ImageWidget));
